export const headerEn = {
  links: [
    {
      id: '1',
      name: 'Our Spaces',
      url: '/',
      subMenuLinks: [
        {
          id: '1',
          pageName: 'Training Space',
          pathUrl: '/training-space',
        },
        {
          id: '2',
          pageName: 'Entrepreneurship space ',
          pathUrl: '/entrepreneurship',
        },
        { id: '3', pageName: 'Event Space', pathUrl: '#' },
      ],
    },
    { id: '2', name: 'The elife project', url: '/about' },
    { id: '3', name: 'Our training centers', url: '/center' },
    { id: '4', name: 'contact', url: '/contact' },
  ],
  btnopenMenu: 'My space',
  listMenu: [
    {
      id: '1',
      item: 'My Profil',
      imgSrc: 'profiluser.png',
      link: '/profile',
    },
    {
      id: '2',
      item: 'My favorites',
      imgSrc: 'favorites.png',
      link: '/favorites',
    },
    {
      id: '3',
      item: 'My demands',
      imgSrc: 'demandes.png',
      link: '/demande',
    },
  ],
  btn: 'Logout',
};

export const footerEn = {
  linksFooter: [
    { id: '1', name: 'Our Spaces', url: '/' },
    { id: '2', name: 'The elife project', url: '/about' },
    { id: '3', name: 'Our training centers', url: '/center' },
    { id: '4', name: 'Contact', url: '/' },
  ],
};

export const headerFr = {
  links: [
    {
      id: '1',
      name: 'Nos Espaces',
      url: '/',
      subMenuLinks: [
        {
          id: '1',
          pageName: 'Espace Formation',
          pathUrl: '/training-space',
        },
        {
          id: '2',
          pageName: 'Espace Entrepreneuriat',
          pathUrl: '/entrepreneurship',
        },
        { id: '3', pageName: 'Espace Evènement', pathUrl: '#' },
      ],
    },
    { id: '2', name: 'Le projet elife', url: '/about' },
    { id: '3', name: 'Nos centres de formation', url: '/center' },
    { id: '4', name: 'Contact', url: '/contact' },
  ],

  btnopenMenu: 'Mon espace',
  listMenu: [
    {
      id: '1',
      item: 'Mon profil',
      imgSrc: 'profiluser.png',
      link: '/profile',
    },
    {
      id: '2',
      item: 'Mes favoris',
      imgSrc: 'favorites.png',
      link: '/favorites',
    },
    {
      id: '3',
      item: 'Mes demandes',
      imgSrc: 'demandes.png',
      link: '/demande',
    },
  ],
  btn: 'Déconnexion',
};

export const footerFr = {
  linksFooter: [
    { id: '1', name: 'Nos Espaces', url: '/' },
    { id: '2', name: 'Le projet elife', url: '/about' },
    { id: '3', name: 'Nos centres de formation', url: '/center' },
    { id: '4', name: 'Contact', url: '/contact' },
  ],
};
