import facebookIcon from '../../images/Layout/Footer-icons/facebook.svg';
import TwitterIcon from '../../images/Layout/Footer-icons/twitter.svg';
import InstagramIcon from '../../images/Layout/Footer-icons/insta.svg';
import LinkedinIcon from '../../images/Layout/Footer-icons/linkedin.svg';

export const socialIcons = [
  {
    id: 1,
    url: 'https://www.facebook.com/programmeelife/',
    icon: facebookIcon,
    name: 'facebook',
  },
  {
    id: 2,
    url: 'https://twitter.com/tunisie_le',
    icon: TwitterIcon,
    name: 'twitter',
  },
  {
    id: 3,
    url: '/',
    icon: InstagramIcon,
    name: 'instagram',
  },
  {
    id: 4,
    url: 'https://www.linkedin.com/company/ftpd/',
    icon: LinkedinIcon,
    name: 'Linkedin',
  },
];
