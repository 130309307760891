/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby';
// UI lib comoponents
import { Container, Row, Visible } from 'react-grid-system';
// fake API
import { socialIcons } from './social-media-links';

// Style
import './index.scss';
import { footerEn, footerFr } from '../Helpers/pageLayoutData';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PageFooter() {
  /* ******************************** CONSTANTS ******************************* */

  /* ********************************** HOOKS ********************************* */

  // Localization
  const { language } = useI18next();
  const { linksFooter } = language === 'en' ? footerEn : footerFr;

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ***************************** RENDER HELPERS ***************************** */
  // for large screen
  const linksMap = linksFooter.map(({ id, url, name }) => (
    <li key={id}>
      <Link to={url}>{name}</Link>
    </li>
  ));
  // for small screens :
  const linksMapFirstRow = linksFooter.slice(0, 3).map(({ id, url, name }) => (
    <li key={id}>
      <Link to={url}>{name}</Link>
    </li>
  ));
  const linksMapSecondRow = linksFooter
    .slice(3, linksFooter.length)
    .map(({ id, url, name }) => (
      <li key={id}>
        <Link to={url}>{name}</Link>
      </li>
    ));
  // social Media links:
  const socialMedia = socialIcons.map(({ id, url, icon, name }) => (
    <Link key={id} to={url}>
      <img src={icon} alt={name} />
    </Link>
  ));
  /* ******************************** RENDERING ******************************* */

  return (
    <>
      <Visible sm xs xxs>
        <Container className="page-footer" fluid>
          <div className="icons-section ">{socialMedia}</div>
          <div className="links-section-row-one">{linksMapFirstRow}</div>
          <Row className="links-section-row-two" justify="around">
            {linksMapSecondRow}
          </Row>
          <div className="copyright-section flex items-center justify-around">
            <p className="copyright">
              &copy; Copyright Elife. All Rights Reserved
            </p>
            <p>
              Designed by
              <span>EMIKETIC</span>
            </p>
          </div>
        </Container>
      </Visible>
      <Visible xxl xl lg md>
        <Container className="page-footer" fluid>
          <div className="icons-section ">{socialMedia}</div>
          <Row className="links-section " align="center" justify="around">
            {linksMap}
          </Row>
          <div className="copyright-section flex items-center justify-around">
            <p className="copyright">
              &copy; Copyright Elife. All Rights Reserved
            </p>
            <p>
              Designed by
              <span>EMIKETIC</span>
            </p>
          </div>
        </Container>
      </Visible>
    </>
  );
}

PageFooter.propTypes = {};

PageFooter.defaultProps = {};

export default PageFooter;
