/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { Container } from 'react-grid-system';

// UI lib component
import PageFooter from '../PageFooter';

// helpers
import { isLoggedIn, protectedRoutes } from '../Services/auth';
import { isBrowser } from '../Helpers/isBrowser';

// Style
import './index.scss';

// Local UI components
const PageHeader = React.lazy(() => import('../PageHeader'));

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
/**
 * PageLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
function PageLayout({ children }) {
  /* ********************************** HOOKS ********************************* */
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/').filter((value) => value.length > 0);
  const route = splitPathname[splitPathname.length - 1];

  function protectRoutes() {
    if (!isLoggedIn()) {
      if (protectedRoutes.includes(route) || route >= 0) {
        navigate('/');
      }
    }
  }

  React.useEffect(() => {
    protectRoutes();
  }, [pathname]);

  /* ******************************** RENDERING ******************************* */

  return (
    <Container className="page-layout" fluid>
      {isBrowser() ? (
        <React.Suspense fallback={null}>
          <PageHeader />
        </React.Suspense>
      ) : null}
      <main>{children}</main>
      <PageFooter />
    </Container>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
